@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/_variables";
@import "../../../node_modules/bootstrap/scss/_mixins";
@import "~bootstrap/scss/_modal.scss";
@import "~bootstrap/scss/_buttons.scss";

.metrics {
    background-color: rgb(255, 254, 224);
    padding: 0 2rem;
    @include getNavbarStyles;
    span {
        font-weight: 700;
        color: rgb(109, 109, 109);
    }

    .grid-one {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        height: 40%;
        .top__card {
            @include getCardStyles;
        }
        .top__card__info {
            margin-bottom: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include getCardIconStyle;
        }
        @include getMonthlyFollowersStyles;
        margin-bottom: 1rem;
    }

    .grid-two {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        height: 40%;

        @include getTrendingTracksStyles;
        @include getTrendingUsersStyles;
        @include getUserActivityStyles;
    }
}
