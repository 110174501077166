@mixin getNavbarStyles {
    .navbar {
        margin: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .mobile-info {
            display: none;
            @media screen and (max-width: 768px) {
                display: flex;
            }
        }
        .info {
            display: flex;
            align-items: center;
            gap: 1rem;
            @media screen and (max-width: 768px) {
                display: none;
            }
            .avatar {
                img {
                    border-radius: 3rem;
                    height: 3rem;
                }
            }
        }
    }
}
