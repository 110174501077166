@mixin getCardIconStyle {
    .icon {
        cursor: pointer;
        svg {
            color: #000;
            font-size: 1.4rem;
        }
        border-radius: 0.5rem;

        padding: 0.5rem;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}
