.title {
    margin-bottom: 1rem;
    color: #162969;
}

.card {
    width: 16rem;
    height: 10rem;
    perspective: 50rem;
}

.card__part {
    box-shadow: 1px 1px #aaa3a3;
    top: 0;
    position: absolute;
    z-index: 1000;
    left: 0;
    display: inline-block;
    width: 16rem;
    height: 10rem;
    background-image: linear-gradient(
        to right bottom,
        #fd696b,
        #fa616e,
        #f65871,
        #f15075,
        #ec4879
    ); /*linear-gradient(to right bottom, #fd8369, #fc7870, #f96e78, #f56581, #ee5d8a)*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 0.5rem;

    -webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
}

.card__front {
    padding: 1rem;
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
}

.card__back {
    padding: 1rem 0;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
}

.card__black-line {
    margin-top: 5px;
    height: 2rem;
    background-color: #303030;
}

.card__logo {
    height: 0.9rem;
}

.card__front-logo {
    position: absolute;
    top: 1rem;
    right: 1rem;
}
.card__square {
    border-radius: 5px;
    height: 1.9rem;
}

.card_numer {
    display: block;
    width: 100%;
    word-spacing: 4px;
    font-size: 1rem;
    letter-spacing: 2px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.card__space-75 {
    width: 75%;
    float: left;
}

.card__space-25 {
    width: 25%;
    float: left;
}

.card__label {
    font-size: 0.5rem;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
    letter-spacing: 1px;
}

.card__info {
    margin-bottom: 0;
    margin-top: 5px;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.card__back-content {
    padding: 0.75rem 0.75rem 0;
}
.card__secret--last {
    color: #303030;
    text-align: right;
    margin: 0;
    font-size: 0.75rem;
}

.card__secret {
    padding: 5px 12px;
    background-color: #fff;
    position: relative;
}

.card__secret:before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    height: calc(100% + 6px);
    width: calc(100% - 42px);
    border-radius: 4px;
    background: repeating-linear-gradient(45deg, #ededed, #ededed 5px, #f9f9f9 5px, #f9f9f9 10px);
}

.card__back-logo {
    position: absolute;
    bottom: 0.75rem;
    right: 0.75rem;
}

.card__back-square {
    position: absolute;
    bottom: 0.75rem;
    left: 0.75rem;
}

.card:hover .card__front {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
}

.card:hover .card__back {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
}
