.player {
  background-color: rgba(#000000, 0.2);
  padding: 3rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  &__image {
    position: relative;
    img {
      position: absolute;
      bottom: 1rem;
      height: 3rem;
      right: -1.5rem;
    }
  }
  &__track__artist {
    margin-bottom: 1rem;
    font-weight: 300;
  }
  &__track__seek__info {
    margin-top: 0.1rem;
    display: flex;
    justify-content: space-between;
  }
  &__options {
    margin-top: 1rem;
    display: flex;
    justify-content: space-evenly;
  }
}
