@mixin getTrendingTracksStyles {
    .transactions__title {
        @include getCardStyles;
        .transactions__info {
            margin-bottom: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include getCardIconStyle;
        }
        .transaction {
            display: flex;
            margin-bottom: 1.4rem;
            gap: 1rem;
            justify-content: space-between;
            @media (max-width: 1080px) {
                flex-direction: column;
            }
            .transaction__info {
                gap: 1rem;
                width: 40%;
                display: flex;
                img {
                    border-radius: 1rem;
                    height: 3rem;
                }
            }
            .transaction__meta {
                display: flex;
                gap: 1rem;
            }
        }
    }
}
