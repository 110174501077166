.sidebar {
    background-image: radial-gradient(circle 580.6px at 10% 40%, rgb(255, 254, 200) 10%, rgb(255, 253, 175) 100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .upper__container {
        .brand {
            cursor: pointer;
            img {
                height: 8rem;
                margin-top: 1rem;
            }
        }
        .links {
            .links-list {
                display: flex;
                flex-direction: column;
                padding-left: 3.5rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
            li {
                padding-right: 0;
                list-style-type: none;
                margin: 0.5rem 0;
                display: flex;
                align-items: center;
                gap: 1rem;
                font-size: 1.2rem;
                svg {
                    color: black;
                }
                a {
                    text-decoration: none;
                    color: black;
                }
            }
        }
    }
    .lower__container {
        .logout-button-container {
            margin-bottom: 2rem;
            display: flex;
            align-content: center;
            gap: 1rem;
            cursor: pointer;
            .logout-button-icon {
                margin-top: 0.1rem;
            }
        }
    }
}
